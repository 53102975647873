.music {
	&.container {
		overflow: hidden;
		min-height: 101vh;
		position: relative;
		height: 100vh;
		@include breakpoint(sm) {
			overflow: visible;
			&.container {
				padding: 20px;
				padding-top: 150px;
				height: auto; } } }

	.album-is-active & {
		height: auto;
		overflow: scroll; }
	.album-is-active .title {
		opacity: .2; }
	.title {
		// font-size: 32px
		right: 0;
		@include transitionSlow;
		@include breakpoint(sm) {
			left: 20px;
			right: auto;
			text-align: left;
			font-size: 35px; } }
	.subtitle {
		display: none; } }
.album {
	// height: 400px
	// width: 380px
	top: 0;
	left: 0;
	min-height: 100vh;
	height: 100%;
	width: 100%;
	position: absolute;
	@include transitionSlow;
	pointer-events: none;
	@include breakpoint(sm) {
		position: static;
		margin: 40px 0;
		border-bottom: 2px solid $black; }
	.album-is-active &:not(.is-active) {
		opacity: .1;
		pointer-events: none;
		.album__img {
			pointer-events: none; } }
	.is-loading img {
		opacity: 0;
		transform: none !important; }
	&.is-active &__img {
		top: 180px;
		left: 20px;
		width: 22vw;
		height: 22vw;
		transform: none !important;
		transition-delay: 0s !important; }
	&.is-active &__content {
		opacity: 1;
		pointer-events: all;
		top: 150px;
		display: block; }
	&__img {
		@include transitionSlow;
		filter: brightness(0.8);
		cursor: pointer;
		width: 100%;
		height: 100%;
		height: 30vmin;
		width: 30vmin;
		left: calc(50% - 150px);
		top: 50%;
		left: calc(50% - calc(30vmin / 2));
		top: calc(50% - 5vmin);
		pointer-events: all;
		position: fixed;
		@extend .z-depth-5;
		@include breakpoint(sm) {
			position: static;
			width: 100%;
			height: auto;
			transform: none !important;
			filter: none; }
		&:hover {
			filter: brightness(1.2); } }
	// opacity: 0
	&:nth-child(1) &__img {
		transform: translate(0, -10vmin);
		z-index: 10; }
	&:nth-child(2) &__img {
		transform: translate(-20vmin, -20vmin); }
	&:nth-child(3) &__img {
		transform: translate(22vmin, -26vmin); }
	&:nth-child(4) &__img {
		transform: translate(-32vmin, 11vmin); }
	&:nth-child(5) &__img {
		transform: translate(27vmin, 10vmin); }
	&:nth-child(6) &__img {
		transform: translate(7vmin, 18vmin); }
	&__content {
		position: absolute;
		display: none;
		left: 30vw;
		top: 250px;
		width: 60vw;
		padding-bottom: 100px;
		opacity: 0;
		pointer-events: none;
		transition: opacity $transition2, top $transition2 0.2s;
		@include breakpoint(sm) {
			width: 100%;
			position: static;
			display: block;
			opacity: 1;
			pointer-events: all; }
		@include breakpoint(lg) {
			width: 50vw; }
		// a
		// 	background: $black
		// 	border: 1px solid $black
		// 	padding: 10px 30px
		// 	color: $white
		// 	text-decoration: none
		// 	margin-bottom: 10px
 }		// 	display: inline-block

	&__close {
		border: 1px solid rgba(55, 55, 55, 0.9);
		position: fixed;
		// top: 50vmin
		// top: 20px
		// left: 50px
		// height: 50px
		// width: 50px
		width: 65px;
		height: 65px;
		z-index: 300;
		position: fixed;
		left: 20px;
		top: 20px;
		pointer-events: none;
		opacity: 0;
		-webkit-appearance: none;
		background: none;
		@include transition;
		@include breakpoint(dt) {
			&:hover {
				transform: scale(1.1); } }
		.album-is-active & {
			pointer-events: all;
			opacity: 1; }

		span {
			position: absolute;
			left: 25%;
			top: 50%;
			width: 50%;
			height: 1px;
			background: black;
			transform: rotate(-45deg);
			&:first-child {
				transform: rotate(45deg); } } } }



