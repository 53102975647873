.front {
    // height: 101vh
    height: 100vh;
    overflow: hidden;
    .header__logo {
        left: 60px;
        top: 60px;
        @include breakpoint(md) {
            top: 80px; }
        @include breakpoint(sm) {
            left: 20px;
            top: 25px; }
        svg {
            // .wrapper-loading &
            path {
                opacity: 0; }
            // width: 1600px
            height: 120px;
            @include breakpoint(md) {
                height: 80px; }
            @include breakpoint(sm) {
                height: 60px; } } }

    .subtitle {
        h2 {
            font-family: "Josefin slab";
            font-weight: 100;
            opacity: 1;
            color: white !important; }
        @include breakpoint(md) {
            top: 180px; }
        @include breakpoint(sm) {
            top: 100px;
            left: 20px;
            text-align: center;
            h2 {
                font-size: 26px; } } }


    .background__image {
        background-position: center 0% !important;
        filter: brightness(1); } }
