* {
    box-sizing: border-box; }

html {
    font-family: 'Playfair','sans-serif';
    font-family: 'Didot','sans-serif';
    font-family: 'Vollkorn','sans-serif';
    -mox-osx-smoothing: grayscaled;
    font-smoothing: antialiased;
    font-size: 20px;
    overflow-scrolling: touch;
    margin-top: 0 !important;
    overflow-x: hidden;
    width: 100%;
 }    // height: 200vh

html, body {
    width: 100%;
    overflow-x: hidden; }
body {
    &.in-transition {
        transition: transform 0.4s; } }
// WORDPRESS CLASSES

.inner-wrapper {
    visibility: visible !important; }


.alignleft {
    float: left;
    margin-right: 20px; }

.alignright {
    float: right;
    margin-left: 20px; }

.right {
    float: right; }
.left {
    float: left; }

.size-thumbnail {}

.size-full {}

// POSITIONS

.hide {
    display: none; }

.hidden {
    visibility: hidden !important; }

.visible {
    visibility: visible !important; }

.no-alpha {
    opacity: 0 !important; }

.full-height {
    min-height: 100vh; }


.flex {
    display: flex;
    &-center {
        @extend .flex;
        justify-content: center;
        align-items: center; } }

.zoomable {
    transition: all .25s;
    backface-visibility: hidden;
    &:hover {
        transform: scale(1.05); }
    &-svg {
        svg {
            @include transition; }
        &:hover {
            svg {
                transform: scale(1.2); } } }
    &-img {
        img {
            @include transition; }
        &:hover {
            img {
                transform: scale(1.2); } } } }

.z-depth-1 {
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2); }
.z-depth-2 {
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3); }
.z-depth-3 {
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.3); }
.z-depth-4 {
    box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.3); }
.z-depth-5 {
    box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.3); }

button {
    border: none;
    outline: 0; }
img {
    max-width: 100%;
    height: auto; }

.divider {
    display: block;
    width: 100%;
    height: 2px;
    background: black;
    margin: 2.5rem 0;
    &-short {
        @extend .divider;
        width: 60px;
        &-white {
            @extend .divider-short;
            background: white; }
        &-grey {
            @extend .divider-short;
            background: #979797; } }
    &-white {
        @extend .divider;
        background: white; } }


// CUSTOM


iframe {
    max-width: 100%; }
