// @include font-face('SabreW90-Regular','../fonts/SabreW90-Regular','eot')
// @include font-face('SabreW90-Regular','../fonts/SabreW90-Regular','ttf')

// @include font-face('OpenSans','../fonts/OpenSans/OpenSans-Semibold','eot ttf')

h1, h2, h3, h4 {
	font-family: vollkorn;
	word-break: break-word;
	.dark & {
		color: $white; }
	.light & {
		color: $black; } }

h1 {
	font-family: Archivo Black;
	font-size: 66px;
	line-height: 70px;
	min-height: 90px;
	margin: 0;
	@include breakpoint(sm) {
		font-size: 44px;
		line-height: 50px; }
 }	// color: rgba(yellow, 0.6) !important

.subtitle h2 {
	font-size: 36px;
	line-height: 42px;
	letter-spacing: 2px;
	font-family: Archivo Black;
	font-family: 'Didot','sans-serif';
	opacity: 0.7; }

h3 {
	font-size: 30px;
	line-height: 32px; }

h4 {
	font-size: 24px;
	line-height: 32px; }

p {
	// font-weight: 400
	// font-size: 18px
	// line-height: 26px
	max-width: 1500px;
	font-family: Roboto;
	font-weight: 400;
	font-style: normal;
	// font-size: 14.5px
	// line-height: 22px
	font-size: 16px;
	line-height: 26px;

	.dark & {
		color: $white; }
	.light & {
		color: $black; } }

i {
	font-style: italic;
	font-weight: 400; }

strong {
	font-weight: 700; }


nav {
	ul {
		padding: 0;
		list-style-type: none; }
	li, a {
		list-style-type: none !important;
		text-decoration: none; } }
ul {
	padding-left: 1rem;
	list-style-type: initial;
	li {
		@extend p;
		list-style-type: initial; } }

