.grid {
	.dark & {
 }		// background: $blacks
	.container {
		width: 80%;
		margin: 0 auto;
		padding-top: 450px;
		.contact & {
			padding-top: 300px; }
		@include breakpoint(lg) {
			width: 70%; }
		@include breakpoint(md) {
			width: 90%; }
		@include breakpoint(sm) {
			padding-top: 420px;
			.contact & {
				padding-top: 200px; } } }
	&__row {
		// opacity: 0
 }		// border-bottom: 1px solid $black
	&__column {
		padding: 1rem;
		.music & {
			padding: 1rem 0.5rem; }
		@include breakpoint(md) {
 }			// text-align: center
		@include breakpoint(sm) {
			padding: 0; } }
	// .gallery-item
	// 	padding: 1rem
	img {
		max-width: 100% !important;
		height: auto;
		border: none !important; }
	// a
	// 	background: $black
	// 	border: 1px solid $black
	// 	padding: 10px 30px
	// 	color: $white
	// 	text-decoration: none
	// 	margin-bottom: 10px
 }	// 	display: inline-block

