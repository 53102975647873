.header {
    width: 100%;
    z-index: 210;
    position: absolute;
    border-bottom: 1px solid white;
    &__logo {
        height: 100%;
        position: absolute;
        top: 20px;
        left: 20px;
        @include transition;
        .nav-is-open & {
            opacity: 0.1; }
        @include breakpoint(md);
        .music & {
            display: none; }
        svg {
            @include transition;
            height: 80px;
            width: auto;
            @include breakpoint(sm) {
                height: 60px; } }
        path {
            // fill: white !important
            opacity: 0;
            fill: white;
            transition: fill 1s 1s;
            stroke: white;
            .wrapper-loading & {
                fill: transparent; }
            .contact &, .music & {
                fill: $black;
                stroke: $black; } } } }


.nav {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 200;
    display: flex;
    flex-flow: column;
    justify-content: center;
    // transform: translateX(100%)
    opacity: 0;
    pointer-events: none;
    // background: $black
    .nav-is-open & {
        pointer-events: all;
        opacity: 1; }
    .light & {
 }        // background: $white
    &__menu {
        text-align: center; }
    li {
        height: 70px;
        position: relative;
        overflow: hidden;
        max-width: none;
        @include breakpoint(sm) {
            height: 60px; } }
    a {
        @extend h1;
        font-family: archivo black;
        text-transform: uppercase;
        display: inline-block;
        transform: translateY(70px);
        width: auto;
        transition: color 0.4s;
        @include breakpoint(sm) {
            font-size: 40px; }
        &:hover {
            color: rgba(red, 0.5) !important; }
        // +transitionSlow
        color: $white;
        .dark & {}
        .light & {
            color: $white; } }
    &__toggle {
        // display: none
        width: 65px;
        height: 65px;
        z-index: 300;
        position: fixed;
        right: 20px;
        top: 20px;
        appearance: none;
        background: none;
        @include transition;
        border: 1px solid $white;
        @include breakpoint(sm) {
            // width: 50px
            // height: 50px
            right: 0px;
            top: 15px;
            border: none !important; }
        .wrapper-loading & {
            opacity: 0 !important; }
        .contact &, .music & {
            border: 1px solid $black; }
        .nav-is-open & {
            border-color: $white !important; }
        span {
            width: 50%;
            left: 25%;
            border-bottom: 2px solid white;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            display: block;
            @include transition;
            position: absolute;
            transform-origin: 50%;
            border-color: $white;
            .contact &, .music & {
                border-color: $black; }
            .nav-is-open & {
                border-color: $white !important; }
            // .dark &
            // .light &
            &:first-child {
                top: 35%;
                .nav-is-open & {
                    top: 50%;
                    transform: rotate(45deg); } }
            &:nth-child(2) {
                top: 50%;
                .nav-is-open & {
                    opacity: 0; } }
            &:last-child {
                top: 65%;
                .nav-is-open & {
                    top: 50%;
                    transform: rotate(-45deg); } } } }
    &__secondary {
        position: absolute;
        left: 20px;
        // top: 200px
        // top: 430px
        bottom: calc(100% - 420px);
        background: white;
        z-index: 50;
        // top: 200px
        @include breakpoint(sm) {
            left: 5%;
            bottom: calc(100% - 380px); }
        a {
            display: block;
            text-decoration: none;
            text-transform: uppercase;
            @extend h4 !optional;
            color: $black !important;
            padding: 0 5px;
            margin: 20px;
            @include transition;
            &:hover {
                // background: black
                // color: $white !important
                text-decoration: line-through; } } } }


// .border-overlay
//     display: none
//     +coverall
//     position: fixed
//     +transitionSlow
//     z-index: 50
//     pointer-events: none
//     span
//         position: absolute
//         .dark &
//             background: $white
//         .light &
//             background: $black
//     &__top, &__bottom
//         width: calc( 100% - 40px)
//         // width: 100%
//         height: 1px
//     &__left, &__right
//         height: calc( 100% - 40px)
//         // height: 100%
//         width: 1px
//     &__top
//         top: 20px
//         left: 20px
//     &__right
//         top: 20px
//         right: 20px
//     &__bottom
//         bottom: 20px
//         left: 20px
//     &__left
//         left: 20px
//         top: 20px

.bar-overlay {
    @include coverall;
    position: fixed;
    // transition: all 2s
    z-index: 100;
    pointer-events: none;
    // display: none
    // background: transparent
    background: black;
    .wrapper-loading & {
        opacity: 1;
        z-index: 100; }
    .front & {
        span {
            // border-left: none
            // width: 0.1%
 } }            // opacity: 0.1
    span {
        position: fixed;
        top: 0;
        height: 0%;
        width: 1px;
        width: 0.1%;
        opacity: 1;
        border-left: 1px solid rgba(white, 0.1);
        background: rgba($black, 0.1);
        transition: background 1s, border-color 1s;
        pointer-events: none;
        // border-color: white !important
        .wrapper-loading & {
            width: 20%;
            background: black !important;
            opacity: 1 !important;
            border-color: rgba(white, 0.3); }
        .nav-is-open & {
            background: rgba(35,35,30,0.95);
            border-color: rgba(white, 0.3);
            opacity: 1; }
        .front & {
 } }            // opacity: 0.4
    // &__one
    //  // left: 0px
    //  // width: 0%
    //  // border: none !important
    // &__two
    //  // left: 20%
    // &__three
    //  // left: 40%
    // &__four
    //  // left: 60%
    // &__five
    //  // left: 80%
    // &__one
    //     left: 0px
    //     width: 0%
    //     border: none !important
    // &__two
    //     left: 10%
    // &__three
    //     left: 36.5%
    // &__four
    //     left: 63%
    // &__five
    //     left: 90%
    &__one {
        left: -2.5%;
        width: 0%;
        border: none !important; }
    &__two {
        left: calc(100% / 6 * 1); // 16.6
        left: 15%; }
    &__three {
        left: calc(100% / 6 * 2); // 33
        left: 32.5%; }
    &__four {
        left: calc(100% / 6 * 3); } // 50%
    &__five {
        left: calc(100% / 6 * 4);  //66
        left: 67.5%; }
    &__six {
        left: calc(100% / 6 * 5); // 83.3
        left: 85%; } }
