.music, .grid, .videos {
    @extend .light !optional; }
.front, .press, .sheets, .contact {
    @extend .dark !optional; }

// main
//     opacity: 0

.title {
    // overflow: hidden
    z-index: 130;
    .music & {
        position: absolute;
        top: 20px;
        left: 0;
        width: 100%;
        text-align: center; }
    .dark & {
        color: $white; }
    .light & {
        color: $black; }
    .grid & {
        color: white; }
    span {
        @include transition;
        position: absolute;
        top: 0;
        left: 0; } }
.subtitle {
    position: absolute;
    top: 220px;
    left: 10%;
    width: 50%;
    z-index: 10;
    // opacity: 0 !important
    // display: none
    // color: rgba(black, 0.8) !important
    // background: rgba(220,220,220,1)
    // background: rgba(black, 0.5)
    // color: rgba(white, 0.6) !important

    // @extend .z-depth-5
    // background: white
    // padding: 40px
    // font-size: 18px
    // letter-spacing: 2px
    // line-height: 25px
    // font-weight: 100
    // +transitionSlow($delay: 0.2s)
    h2 {
        color: $red !important; }
    // .dark &
    //     color: $white
    // .light &
    //     color: $black
    .is-out & {
        transform: translateY(50px);
        opacity: 0; } }

.background {
    &__container {
        height: 100%;
        width: 100%;
        @include coverall;
        position: fixed;
        z-index: -1;
        overflow: hidden;
        @extend .flex-center;
        .grid & {
            height: 450px;
            position: absolute;
            @include breakpoint(sm) {
                height: 400px; } }
        .press & {
            background: $black; }
        .title {
            z-index: 10; } }
    &__image {
        filter: brightness(0.5);
        position: absolute;
        @include coverall;
        transform-origin: center 0; } }

// .title,.subtitle, .background-image,
//     opacity: 0
.content a {
    color: inherit;
    // +transition
    &:hover {
        opacity: .8; } }
a.link {
    text-transform: uppercase;
    border: 2px solid $black;
    border-radius: 2px;
    padding: 10px 20px;
    text-decoration: none;
    color: $black;
    @include transition;
    display: inline-block;
    margin: 5px 0;
    &:hover {
        color: $white;
        background: $black; }
    &--inverted {
        @extend .link;
        color: $white;
        background: $black;
        &:hover {
            color: $black;
            background: $white; } }
    // @extend h1
 }    // font-size: 22px
a.image-link {
    border: none;
    padding: 0;
    &:hover {
        background: none; } }

// pages
.press {
    background: $black;
    p, {
        color: $white; }
    a:not(.image-link) {
        color: $white;
        border-color: white; }
    @for $i from 1 through 5 {
        h#{$i} {
            color: $white; } } }

.contact {
    min-height: 101vh; }
