$number-of-columns: 12;

// $breakpoints: (sm: 320, md: 768, lg: 1025, xl:1440)
$mediaquerries: ( xs: "max-width: $xs+px", sm: "max-width: $sm+px",sm-l: "max-width: $sm+px) and (orientation: landscape", md: "max-width: $md+px", mdonly: "min-width: 768px) and (max-width: $md+px", dt: "min-width: $dt+px", lg: "min-width: $lg+px", xl: "min-width: $xl+px" );
$page-width: 1160px;
$gutter: 20px;

$base-font-size: 16px;


$xs: 320;
$sm: 767;
$md: 1024;
$dt: 1025;
$lg: 1440;
$xl: 1920;



$animationDistance: 50px;

$transition: 0.25s;
$transition2: 0.5s;
$easing: cubic-bezier(.77, 0, .175, 1);

$white: rgba(white, 0.8);
$black: rgba(55,55,55, 0.9);
$red: rgba(red, 0.5);
